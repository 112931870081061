.story-card {
    display: block;
    border-radius: 16px;
    text-decoration: none;
    overflow: hidden;
}

.story-card._dark {
    color: #fff;
}

.story-card__wrap {
    border-radius: 16px;
    background: #fff;
}

.story-card__common {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.story-card:hover {
    color: #353131;
}

.story-card._dark:hover {
    color: #fff;
}

.story-card__title {
    text-underline-offset: 2px;
    margin-bottom: 0;
}

.story-card:hover .story-card__title {
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
    /* border-bottom: 1px solid #353131; */
}

.story-card__emoji {
    width: 48px;
    height: 48px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.story-card__image {
    width: 120px;
    height: 120px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.story-card__image._rounded {
    border-radius: 50%;
}

.story-card__images {
    display: flex;
    align-items: center;
}

.story-card__images-item {
    width: 64px;
    height: 64px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    background-position: center;
    margin-right: -16px;
}

.story-card__description {
    color: rgba(53, 49, 49, 0.6);
}

.story-card._dark .story-card__description {
    color: rgba(256, 256, 256, 0.6);
}

.story-card__illustration {
    height: 355px;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: auto 100%;
}

@media screen and (max-width: 600px) {
    .story-card__common {
        padding: 24px;
    }

    .story-card__title {
        font-size: 26px;
        line-height: 110%;
    }
}
